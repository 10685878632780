<template>
  <div :class="$style.component">
    <div :class="$style.inner">
      <div
        v-for="(spec, index) in item.specs"
        :key="`spec-${index}`"
        :class="$style.specs"
      >
        <div :class="$style.title" v-html="spec.title_specs" />
        <base-bodytext :class="$style.body" :text="spec.body_specs" />
      </div>
      <transition name="t-section">
        <div v-if="showSectionTitle" :class="$style.wrapper">
          <span v-html="item.sectionTitle" :class="$style.sectionTitle" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import BaseBodytext from '@/components/base-bodytext'

export default {
  components: { BaseBodytext },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    },
    isCurrent: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    showSectionTitle() {
      return this.isCurrent && this.item.sectionTitle
    }
  }
}
</script>

<style lang="scss" scoped>
.t-section-enter-active,
.t-section-leave-active {
  transition: opacity var(--long) linear;
}

// to avoid flashing when there are two identicla section titles
.t-section-leave-active {
  transition-delay: var(--xshort);
}

.t-section-enter,
.t-section-leave-to {
  opacity: 0;
}
</style>

<style lang="scss" module>
.component {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inner {
  max-width: 30em;

  @media (min-width: $xlarge) {
    max-width: 40em;
  }
}

.title,
.body {
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-small;

  display: inline;
  text-transform: lowercase;
  overflow-wrap: break-word;
}

.title {
  &::after {
    margin-right: 0.25em;
    content: ':';
  }
}

.body {
  hyphens: none;

  p {
    display: inline;
  }
}

.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 0 var(--gutter) var(--gutter) var(--gutter);
  // margin-bottom: -0.3em; // to align section title with lower border of cpation
}

.sectionTitle {
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-medium;

  text-transform: lowercase;
}
</style>
